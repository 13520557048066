<template>
  <div class="reservation">
    <h1>{{ $t('reservation.main_title') }}</h1>

    <p class="page-subtitle">{{ reservationLoginText || $t('reservation.subtitle') }}</p>

    <LanguageSelect absolutePosition />

    <ValidationObserver v-slot="{ invalid }">
      <form @submit.prevent="onSubmit" class="reservation-form">
        <div class="reservation-number">
          <ValidationProvider name="reservation_number" rules="required|min:1" v-slot="{ errors }">
            <Input v-model="reservationPin"
                  :error="alreadySent ? errors[0] || errorMessage : null"
                  :placeholder="$t('reservation.form.reservation_number.placeholder')"
                  minlength="1"
                  :disabled="true"
            />
          </ValidationProvider>
        </div>

        <div class="keyboard-block">
          <SimpleKeyboard @onChange="onChange" :input="reservationPin"/>
        </div>

        <ButtonInput type="submit" size="large" :disabled="invalid || sending" :loading="sending">
          {{ $t('reservation.action.continue') }}
        </ButtonInput>
      </form>
    </ValidationObserver>

    <FloatingButton position="top" @click="logEvent('onReservationGoBackClicked') & $router.push('/home')">
      <UndoSvg />
      <span>{{ $t('floating_button.action.back') }}</span>
    </FloatingButton>

  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import {
  GET_PROPERTY_DETAIL,
} from '@/store/property';
import Input from '@/components/Form/Input/Input';
import ButtonInput from '@/components/Form/Input/ButtonInput';
import { UPDATE_RESERVATION } from '@/store/reservation';
import LanguageSelect from '@/components/LanguageSelect/index';
import FloatingButton from '@/components/FloatingButton';
import {
  RESERVATION_ACTION_CHECKIN,
  RESERVATION_ACTION_LOST_CARD,
  RESERVATION_ACTION_CHECKOUT,
} from '@/constants/action.type';
import * as endpoints from '@/api/handler.endpoints';
import * as methods from '@/api/handler.methods';
import api from '@/api/handler';
import errorHandler from '@/api/errorHandler';
import SimpleKeyboard from '@/components/SimpleKeyboard';
import UndoSvg from '@/assets/images/icons/undo.svg';
import * as exceptionCode from '@/constants/exceptionsCode';
import flashMessage from '@/helpers/flashMessage';
import { FALLBACK_LANGUAGE, ISO_CODE_TO_LANGUAGE } from '@/constants/trans';
import { logEvent } from '@/helpers/analytics';

export default {
  name: 'Reservation',
  data() {
    return {
      type: this.$route.params.type,
      alreadySent: false,
      reservationPin: null,
      sending: false,
      errorMessage: null,
    };
  },
  components: {
    Input,
    ButtonInput,
    LanguageSelect,
    SimpleKeyboard,
    FloatingButton,
    UndoSvg,
  },
  computed: {
    ...mapGetters({
      property: GET_PROPERTY_DETAIL,
    }),
    reservationLoginText() {
      const fallback = _.get(this.property, `settings.login.${ISO_CODE_TO_LANGUAGE[FALLBACK_LANGUAGE]}`);
      return _.get(this.property, `settings.login.${ISO_CODE_TO_LANGUAGE[this.$i18n.locale] || this.$i18n.locale}`) || fallback;
    },
  },
  methods: {
    logEvent,
    onSubmit () {
      this.alreadySent = true;
      this.findReservation();
    },
    findReservation() {
      this.sending = true;
      this.errorMessage = null;
      api(this, endpoints.ENDPOINT_RESERVATION, methods.GET_RESERVATION)(this.reservationPin)
        .then(({data}) => {
          logEvent('onReservationContinueClicked');

          return this.$store.dispatch(UPDATE_RESERVATION, data)
            .then(() => {
              switch (this.type) {
              case RESERVATION_ACTION_CHECKIN:
                if (_.get(data, 'reservation.pin', '').toLowerCase() === this.reservationPin.toLowerCase()) {
                  this.$router.push('/issue-card');
                  return;
                }

                this.$router.push({
                  name: 'guest-authentication-type',
                  params: {
                    type: RESERVATION_ACTION_CHECKIN,
                  },
                });
                break;
              case RESERVATION_ACTION_LOST_CARD:
                if (!data.rooms || data.rooms.length === 0 || !_.some(data.rooms, (room) => room.dispatched_cards > 0)) {
                  flashMessage(this.$store, this.$t('api.error.issue_spare_card.no_rooms_to_issue'), 'danger');
                  return;
                }

                if (_.get(data, 'reservation.pin', '').toLowerCase() === this.reservationPin.toLowerCase()) {
                  this.$router.push('/issue-card/lost-card');
                  return;
                }

                this.$router.push({
                  name: 'guest-authentication-type',
                  params: {
                    type: RESERVATION_ACTION_LOST_CARD,
                  },
                });
                break;
              case RESERVATION_ACTION_CHECKOUT:
                this.$router.push('/checkout');
                break;
              }
            })
            .catch(() => {
              flashMessage(this.$store, this.$t('api.error.general_error'), 'danger');
              this.sending = false;
            });
        })
        .catch((err) => {
          this.sending = false;
          this.handleError(err);
        });
    },
    onChange(input) {
      this.reservationPin = input;
    },
    handleError(err) {
      const errorCode = _.get(err, 'response.data.responseCode') || 500;
      let message = errorHandler(err.response);
      if (errorCode === 404) {
        message = this.$t('api.error.reservation_not_found');
      } else if (errorCode === 400) {
        const data = _.get(err, 'response.data.responseBody.metadata.data');
        const code = _.get(data, 'code');

        if (code === exceptionCode.RESERVATION_WITHOUT_CHECK_INS && data) {
          if (parseInt(process.env.VUE_APP_DISABLED_CHECKIN, 10) === 1) {
            return this.$store.dispatch(UPDATE_RESERVATION, data)
              .then(() => {
                this.$router.push({
                  name: 'checkin-missing',
                });
              })
              .catch(() => {
                this.sending = false;
              });
          }

          return this.$store.dispatch(UPDATE_RESERVATION, data)
            .then(() => {
              this.$router.push({
                name: 'checkin-list',
              });
            })
            .catch(() => {
              this.sending = false;
            });
        } else if ([
          exceptionCode.ROOM_NOT_FOUND,
          exceptionCode.STAY_ROOM_MISMATCH,
          exceptionCode.DATA_ROOM_MISSING,
          exceptionCode.ROOM_MISSING_IN_PMS,
          exceptionCode.NO_AVAILABLE_ROOM_TO_ASSING,
          exceptionCode.ROOM_NOT_CLEAN,
          exceptionCode.RESERVATION_WITHOUT_ROOMS,
        ].indexOf(code) >= 0) {
          return this.$store.dispatch(UPDATE_RESERVATION, {reservation: data})
            .then(() => {
              this.$router.push({
                name: 'no-rooms-reservation',
              });
            })
            .catch(() => {
              this.sending = false;
            });
        }
      }

      if (!message) {
        message = this.$t('api.error.general_error');
      }
      flashMessage(this.$store, this.$t(message), 'danger');
    },
  },
};
</script>

<style lang="scss">
  .reservation {
    text-align: center;
    width: calc(100% - 4rem);
    max-width: 50rem;
  }

  .page-subtitle {
    max-width: 30rem;
    display: block;
    margin: .5rem auto 2rem;
  }

  .keyboard-block {
    margin-bottom: 1rem;
  }

  .reservation-number {
    .form-input {
      border: 0;
      background: none;
      text-align: center;
      font-size: 1.2rem;
    }
  }
</style>
